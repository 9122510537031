@font-face {
  font-family: 'Lato';
  src: url('../public/fonts/Lato-Black.woff') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../public/fonts/Lato-Bold.woff') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../public/fonts/Lato-Regular.woff') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../public/fonts/Lato-Heavy.woff') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

*,
input {
  font-family: 'Lato', sans-serif;
}

html,
body {
  height: 100%;
  min-width: 320px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: transparent !important;
  color: white !important;
  outline: none;
  background-clip: text;
}

#root {
  display: flex;
  height: 100%;
}